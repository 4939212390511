import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_data_table_virtual = _resolveComponent("v-data-table-virtual")!

  return (_openBlock(), _createBlock(_component_v_data_table_virtual, {
    headers: $data.headers,
    "no-data-text": $data.noDataText,
    items: $props.refData
  }, {
    item: _withCtx(({ item, index }) => [
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, _toDisplayString(index + 1), 1),
        _createElementVNode("td", null, _toDisplayString(item.columns.kycPassed ? "✅" : "❌"), 1),
        _createElementVNode("td", null, _toDisplayString(item.columns.qualified ? "✅" : "❌"), 1),
        _createElementVNode("td", null, _toDisplayString(this.formatCurrency(item.columns.moneyFromBids)), 1)
      ])
    ]),
    _: 1
  }, 8, ["headers", "no-data-text", "items"]))
}