
import ReferralsDetails from "@/components/ReferralsDetails.vue";
import ReferralsCounter from "@/components/ReferralsCounter.vue";

export default {
  name: "ReferralsPage",
  components: {
    ReferralsCounter,
    ReferralsDetails,
  },
  async mounted() {
    const response = await this.axios.get(
      `${process.env.VUE_APP_BACKEND_BASE_URL}/reports/agents`
    );

    console.log(response);
    this.registrations = response.data.registrations;
    this.money = response.data.totalMoneyEarned;
    this.refDetails = response.data.referralsStatistics;
  },
  data() {
    return {
      registrations: 0,
      money: 0,
      refDetails: [],
    };
  },
};
