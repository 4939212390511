import { createApp } from "vue";
import App from "./App.vue";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import VueAxios from "vue-axios";
import axios from "axios";
import keycloakService from "@/services/KeycloakService";
import jwtDecode from "jwt-decode";
import "@mdi/font/css/materialdesignicons.css";
import { VDataTableVirtual } from "vuetify/labs/VDataTable";

(async () => {
  const vuetify = createVuetify({
    components: { ...components, VDataTableVirtual },
    directives,
    icons: {
      defaultSet: "mdi",
    },
  });

  axios.interceptors.request.use(async (req) => {
    const token = await keycloakService.getToken();

    req.headers.Authorization = `Bearer ${token}`;

    return req;
  });

  await keycloakService.login(async () => {
    const token = await keycloakService.getToken();
    const decoded: any = jwtDecode(token);
    if (decoded.realm_access.roles.indexOf("ROLE_AGENT") > -1) {
      createApp(App).use(vuetify).use(VueAxios, axios).mount("#app");
    } else {
      await keycloakService.logout();
    }
  });
})();
