import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_referrals_counter = _resolveComponent("referrals-counter")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_referrals_details = _resolveComponent("referrals-details")!
  const _component_v_layout = _resolveComponent("v-layout")!

  return (_openBlock(), _createBlock(_component_v_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "1" }),
          _createVNode(_component_v_col, { cols: "9" }, {
            default: _withCtx(() => [
              _createVNode(_component_referrals_counter, {
                registrations: $data.registrations,
                money: $data.money
              }, null, 8, ["registrations", "money"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "1" })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_referrals_details, { "ref-data": $data.refDetails }, null, 8, ["ref-data"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}