<template>
  <h1 class="text-h1">{{ money }} руб</h1>
  <h5 class="text-subtitle-1 py-6">Принесли заявки реферралов</h5>

  <h5 class="text-subtitle-1 py-6">
    Всего было <b>{{ registrations }}</b> регистраций по промокоду
  </h5>

  <p @click="copyLink" class="copy-area text-body-1">
    Скопировать реферральную ссылку <v-icon icon="mdi-content-copy" />
  </p>
  <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
    Ссылка успешно скопирована
    <template v-slot:actions>
      <v-btn color="blue" variant="text" @click="snackbar = false"> ❌ </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import keycloakService from "@/services/KeycloakService";
import jwtDecode from "jwt-decode";

export default {
  name: "ReferralsCounter",
  data() {
    return {
      referrals: 0,
      referrerId: "",
      snackbar: false,
      snackbarTimeout: 3000,
    };
  },
  props: {
    registrations: {
      type: Number,
      required: true,
    },
    money: {
      type: Number,
      required: true,
    },
  },
  async mounted() {
    const token = await keycloakService.getToken();
    const decoded = jwtDecode(token);

    this.referrerId = decoded.sub;
  },
  methods: {
    async copyLink() {
      await navigator.clipboard.writeText(
        `${process.env.VUE_APP_REFERRAL_BASE_URL}?refid=${this.referrerId}`
      );
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.copy-area {
  cursor: pointer;
}
</style>
