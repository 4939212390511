
import currency from "@/utils/Numbers";

export default {
  methods: {
    formatCurrency(raw: number): string {
      return currency(raw);
    },
  },
  props: {
    refData: Array<any>,
  },
  data() {
    return {
      headers: [
        {
          title: "№",
          align: "center",
        },
        {
          title: "Верифицирован?",
          key: "kycPassed",
          value: "kycPassed",
          align: "center",
          sortable: true,
        },
        {
          title: "Квал?",
          key: "qualified",
          value: "qualified",
          align: "center",
        },
        {
          title: "Купил акций на сумму, руб.",
          key: "moneyFromBids",
          value: "moneyFromBids",
          sortable: true,
          align: "center",
        },
      ],
      noDataText: "Реферралы на данный момент не зафиксированы",
    };
  },
};
